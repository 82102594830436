<template lang="pug">
.top-banner.is-hidden-mobile.is-flex
  .top-banner__buttons.is-flex
    .top-banner__button.is-flex(v-for="option in options", :key="option.key", :class="{ active: option.active }")
      icon(v-if="option.icon", :iconName="option.icon", :iconColor="option.iconColor")
      v-link(v-if="option.url", type="native", :to="option.url", target="_blank") {{ option.name }}
      span(v-else) {{ option.name }}
  top-header-right-block.is-hidden-touch
</template>

<script>
import TopHeaderShippingReturns from '~~/components/Layout/TopHeader/TopHeaderShippingReturns'

export default {
  name: 'TopBannerComponent',

  components: {
    TopHeaderShippingReturns,
    TopHeaderRightBlock: () =>
      import('~~/components/Layout/TopHeader/TopHeaderRightBlock')
  },

  computed: {
    options() {
      return [
        {
          key: '0fe48d14-1556-46ce-b66b-4ac61c68b67c',
          name: this.$t('common.topBanner.rubies'),
          icon: 'menuStone',
          iconColor: '#bf1c60',
          url:
            this.$site.name === 'ruby'
              ? ''
              : 'https://thenaturalrubycompany.com/',
          active: this.$site.name === 'ruby'
        },
        {
          key: 'c199aca8-bf62-4093-a04d-13431121b6c1',
          name: this.$t('common.topBanner.emeralds'),
          icon: 'menuStone',
          iconColor: '#00A87E',
          url: this.$site.name === 'emerald' ? '' : 'https://www.emeralds.com/',
          active: this.$site.name === 'emerald'
        },
        {
          key: 'd780b502-2eb9-458f-86b2-8e80b7425373',
          name: this.$t('common.topBanner.sapphires'),
          icon: 'menuStone',
          iconColor: '#6690FF',
          url:
            this.$site.name === 'sapphire'
              ? ''
              : 'https://www.thenaturalsapphirecompany.com/',
          active: this.$site.name === 'sapphire'
        },
        {
          key: 'ab30412d-cfae-4701-82e0-492abf20e6e5',
          name: this.$t('common.topBanner.gemstones'),
          icon: 'menuStone',
          iconColor: '#b066ff',
          url:
            this.$site.name === 'gemstone'
              ? ''
              : 'https://www.naturalgemstones.com/',
          active: this.$site.name === 'gemstone'
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.top-banner
  background-color: $dark-gray
  border-bottom: 1px solid $border-color
  justify-content: space-between
  @media (max-width: 375px)
    display: none !important
  &__button
    padding: 7px 20px
    color: white
    align-items: center
    border-right: 1px solid $dark-border-color
    &:last-child
      border-right: unset
    svg
      margin-right: 10px
    a
      color: #ffffff
    &.active
      color: $default-font-color
      background-color: #ffffff
      a
        color: $default-font-color
</style>
