<template lang="pug">
  .tooltip(ref="reference")
    slot(name="target")
    .tooltip-content(ref="content", style="display: none")
      .tooltip-header(v-if="$slots.header")
        slot(name="header")
      .tooltip-body
        slot
</template>

<script>
export default {
  name: 'TooltipComponent',

  props: {
    options: {
      type: Object,
      default: () => ({})
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    instance: null
  }),

  computed: {
    defaultOptions() {
      return {
        theme: 'light',
        content: this.$refs.content.innerHTML,
        allowHTML: true,
        onShow: (instance) => {
          this.$emit('show', instance)
        },
        onHidden: (instance) => {
          this.$emit('hidden', instance)
        }
      }
    }
  },

  beforeDestroy() {
    if (!this.instance) return
    this.$root.$off('hide-tooltips', this.instance.hide)
    this.instance.destroy()
    this.instance = null
  },

  async mounted() {
    if (this.disabled) return
    this.instance = await this.$tippy.insert(this.$refs.reference, {
      ...this.defaultOptions,
      ...this.options
    })
    this.$root.$on('hide-tooltips', this.instance.hide)
  },

  methods: {
    update() {
      this.instance.setContent(this.$refs.content.innerHTML)
    }
  }
}
</script>

<style lang="sass" scoped>
.tooltip
  position: relative
  i
    cursor: pointer
</style>
