<template lang="pug">
  .top-header__shipping-returns.is-flex
    icon(icon-name="earth", :icon-color="$device.isMobile ? $site.primaryColor : '#ffffff'")
    tooltip
      v-link.is-hidden-mobile(slot="target", :to="{name: 'education-index-category', params: { category: 'frequently-asked-questions' }, hash: '#shipping' }") {{ $t('layout.header.firstLine.left.text1') }}
      .tooltip-content
        .tooltip-header
          strong(v-html="$t('layout.header.firstLine.left.tooltipTitle1')")
        .tooltip-body(v-html="$t('layout.header.firstLine.left.tooltipText1')")
    span.is-hidden-mobile *&nbsp;/&nbsp;
    tooltip
      v-link(slot="target", :to="{name: 'education-index-category', params: { category: 'frequently-asked-questions' }, hash: '#return-policy' }") {{ $t('layout.header.firstLine.left.text2') }}
      .tooltip-content
        .tooltip-header
          strong(v-html="$t('layout.header.firstLine.left.tooltipTitle2')")
        .tooltip-body(v-html="$t('layout.header.firstLine.left.tooltipText2')")
    span.is-hidden-mobile *
</template>

<script>
import Tooltip from '~~/components/Common/Tooltip'

export default {
  name: 'TopHeaderShippingReturns',

  components: {
    Tooltip
  }
}
</script>

<style lang="sass" scoped>
.top-header__shipping-returns
  cursor: help
  color: white
  align-items: center
  margin-right: 15px
  a
    text-decoration: underline
  svg
    margin-right: 10px
  .tooltip
    &::v-deep
      *
        color: white
</style>
